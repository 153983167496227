export const safeParseInt = (
  value: string | null | undefined,
  defaultValue?: number,
): number | null => {
  if (value == null) {
    return defaultValue ?? null
  }
  const parsed = parseInt(value, 10)
  return isNaN(parsed) ? (defaultValue ?? null) : parsed
}

export const numberToApproximation = (value: number): string => {
  if (value < 100) return value.toString()
  if (value < 250) return '100+'
  if (value < 500) return '250+'
  if (value < 1000) return '500+'
  if (value < 2000) return '1K+'
  if (value < 3000) return '2K+'
  if (value < 4000) return '3K+'
  if (value < 5000) return '4K+'
  if (value < 10000) return '5K+'
  if (value < 20000) return '10K+'
  if (value < 30000) return '20K+'
  if (value < 40000) return '30K+'
  if (value < 50000) return '40K+'
  if (value < 100000) return '50K+'
  return '100K+'
}
