import { Box, Button, Menu, MenuItem, SvgIcon, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getCurrentEvent } from '../../../selectors/event'
import React, { useState } from 'react'
import apiClient from '../../../shared-components/utils/ApiClient'
import { ChevronRight } from '@mui/icons-material'
import { decapitalizeFirstLetter } from '../../../shared-components/utils/copy'
import {
  getGuestAvatarUrls,
  getGuestName,
} from '../../../shared-components/utils/eventDisplay'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MaybeIcon from '../../../svg/status/Maybe'
import CantIcon from '../../../svg/status/Cant'
import BashButton, {
  BashButtonType,
} from 'shared-components/buttons/BashButton'
import Row from '../../../components/Row'
import Column from '../../../components/Column'
import { RsvpStatus } from '../../../types/types'
import { GuestDto } from '../../../types/guest'
import ConfirmBottomSheet from '../ConfirmBottomSheet'
import { editEvent } from '../../../actions/event'
import { setSnackbar } from '../../../shared-components/redux/notifications/actions'

// interface Guest {
//   id: number
//   status?: RsvpStatus
//   tickets?: TicketGuestDto[]
//   questionsCompleted?: boolean
//   totalTicketCount?: number
//   activeTicketCount?: number
//   waitingList?: boolean
//   hype?: boolean
// }

interface GuestManagementChangeStatusProps {
  guest: GuestDto
  setGuest: (guest: GuestDto) => void
  onBack: () => void
}

// Type guard to convert any null values to undefined for type safety
function nullToUndefined<T>(value: T | null): T | undefined {
  return value === null ? undefined : value
}

export const updateStatusCounts = (
  event: any,
  dispatch: any,
  guest: GuestDto,
  fromStatus: string | undefined,
  toStatus: string,
) => {
  let toSubtract = ''
  let toAdd = ''
  if (fromStatus === toStatus) {
    return
  }
  if (fromStatus === RsvpStatus.JOINED) toSubtract = RsvpStatus.JOINED
  if (fromStatus === RsvpStatus.GOING) toSubtract = RsvpStatus.GOING
  if (fromStatus === RsvpStatus.MAYBE) toSubtract = RsvpStatus.MAYBE
  if (fromStatus === RsvpStatus.CANT) toSubtract = RsvpStatus.CANT
  if (fromStatus === 'REQUESTED') toSubtract = 'requested'
  if (!fromStatus) {
    if (guest.hype) {
      toSubtract = 'interested'
    } else {
      if (guest.waitingList) {
        toSubtract = 'waitingList'
      } else {
        toSubtract = 'invited'
      }
    }
  }

  if (toStatus === RsvpStatus.JOINED) toAdd = RsvpStatus.JOINED
  if (toStatus === RsvpStatus.GOING) toAdd = RsvpStatus.GOING
  if (toStatus === RsvpStatus.MAYBE) toAdd = RsvpStatus.MAYBE
  if (toStatus === RsvpStatus.CANT) toAdd = RsvpStatus.CANT
  if (toStatus === 'REQUEST_DENIED') toAdd = 'requestDenied'

  dispatch(
    editEvent({
      ...event,
      statusCountsHost: {
        ...event.statusCountsHost,
        [toSubtract]: Math.max(event.statusCountsHost?.[toSubtract] - 1, 0),
        [toAdd]: event.statusCountsHost?.[toAdd] + 1,
      },
    }),
  )
}

const GuestManagementChangeStatus: React.FC<
  GuestManagementChangeStatusProps
> = ({ guest, setGuest, onBack }) => {
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const { t } = useTranslation('common')
  const [localStatus, setLocalStatus] = useState<RsvpStatus | 'NONE'>(
    guest.status != null &&
      [RsvpStatus.GOING, RsvpStatus.MAYBE, RsvpStatus.CANT].includes(
        guest.status,
      )
      ? guest.status || 'NONE'
      : guest.status === RsvpStatus.JOINED
        ? RsvpStatus.GOING
        : 'NONE',
  )
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirming, setConfirming] = useState(false)

  const showGivingTicketWarning =
    event.hasJoinOptions &&
    ([RsvpStatus.GOING, RsvpStatus.JOINED] as (RsvpStatus | 'NONE')[]).includes(
      localStatus,
    ) &&
    !(
      [RsvpStatus.GOING, RsvpStatus.JOINED] as (RsvpStatus | 'NONE')[]
    ).includes(guest.status ?? 'NONE')

  const hasMultipleJoinOptions = (event.joinOptions?.length ?? 0) > 1

  const secondaryText = () => {
    const ticketName =
      hasMultipleJoinOptions && guest.tickets
        ? (guest.tickets?.[0]?.name ?? t('guestList')) +
          ` (${guest.status === 'REQUESTED' ? guest.tickets?.length : guest.totalTicketCount})`
        : null
    const answers = guest.questionsCompleted ? t('questions.answers') : null
    const answersText =
      ticketName == null
        ? answers
        : answers == null
          ? null
          : decapitalizeFirstLetter(answers)

    return [ticketName, answersText].filter((s) => s != null).join(' + ')
  }

  const onDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onDropdownItemClick = (status: RsvpStatus) => {
    setLocalStatus(status)
    handleMenuClose()
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const onConfirmClose = () => {
    setConfirmOpen(false)
  }

  const onConfirmSave = async () => {
    setConfirming(true)
    if (localStatus === RsvpStatus.GOING && event.hasJoinOptions) {
      await apiClient.guest.giveTickets(guest.id, 1)
      setGuest({
        ...guest,
        totalTicketCount: 1,
        activeTicketCount: 1,
        status: RsvpStatus.JOINED,
      })
      updateStatusCounts(
        event,
        dispatch,
        guest,
        nullToUndefined(guest.status),
        RsvpStatus.JOINED,
      )
    } else {
      await apiClient.guest.patch(
        guest.id,
        {
          status: localStatus,
        },
        null,
      )
      setGuest({
        ...guest,
        status: localStatus,
        totalTicketCount: 0,
      })
      updateStatusCounts(
        event,
        dispatch,
        guest,
        nullToUndefined(guest.status),
        localStatus,
      )
    }

    setConfirming(false)
    setConfirmOpen(false)
    onBack()
  }

  const onSaveClick = async () => {
    if (localStatus === 'NONE') {
      dispatch(setSnackbar('info', t('selectAStatus')))
      return
    }

    const selectedJoinOption = event.joinOptions?.find(
      (j: any) => j.id === guest.tickets?.[0]?.joinOptionId,
    )

    const ticketWasPaid =
      selectedJoinOption == null || selectedJoinOption.price > 0
    if (
      (
        [RsvpStatus.JOINED, RsvpStatus.GOING] as (RsvpStatus | 'NONE')[]
      ).includes(guest.status ?? 'NONE') &&
      [RsvpStatus.MAYBE, RsvpStatus.CANT].includes(localStatus)
    ) {
      if (ticketWasPaid) {
        setConfirmOpen(true)
      } else {
        onConfirmSave()
      }
      return
    }
    if (
      [
        RsvpStatus.MAYBE,
        RsvpStatus.CANT,
        null,
        undefined,
        RsvpStatus.REQUESTED,
        RsvpStatus.REQUEST_DENIED,
      ].includes(guest.status as RsvpStatus | null | undefined) &&
      localStatus === RsvpStatus.GOING
    ) {
      onConfirmSave()
      return
    }

    if ([RsvpStatus.MAYBE, RsvpStatus.CANT].includes(localStatus)) {
      await onConfirmSave()
    }

    onBack()
  }

  return (
    <>
      <Column sx={{ gap: 3 }}>
        <Row
          sx={{
            alignItems: 'center',
            gap: 1,
            p: '16px 12px',
            bgcolor: 'grey.150',
            borderRadius: 1,
          }}
        >
          <Box
            component='img'
            alt='Guest avatar'
            src={getGuestAvatarUrls(guest).lg}
            sx={{
              width: 40,
              height: 40,
              borderRadius: 100,
            }}
          />
          <Column sx={{ gap: 0.5 }}>
            <Typography variant='body1' sx={{ fontWeight: 500 }}>
              {getGuestName(guest)}
            </Typography>
            <Typography variant='caption'>{secondaryText()}</Typography>
          </Column>

          <Button
            sx={[
              {
                ml: 'auto',
                p: 1,
                bgcolor: 'background.paper',
                border: '1px solid',
                borderColor: 'grey.200',
                borderRadius: 1,
                '& svg': {
                  width: 20,
                  height: 20,
                },
              },
              {
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              },
            ]}
            onClick={onDropdownClick}
          >
            {(
              [RsvpStatus.JOINED, RsvpStatus.GOING] as (RsvpStatus | 'NONE')[]
            ).includes(localStatus) && (
              <CheckCircleIcon sx={{ color: '#8B30F3' }} />
            )}
            {localStatus === RsvpStatus.MAYBE && (
              <SvgIcon component={MaybeIcon} sx={{ color: '#2E60DC' }} />
            )}
            {localStatus === RsvpStatus.CANT && (
              <SvgIcon component={CantIcon} sx={{ color: '#EE6C4D' }} />
            )}
            <Typography
              variant='body2'
              sx={{
                ml: localStatus === 'NONE' ? '6px' : 'unset',
              }}
            >
              {t(`shared:dropdownRsvp.${localStatus}`)}
            </Typography>

            <ChevronRight sx={{ transform: 'rotate(90deg)' }} />
          </Button>
        </Row>
        {showGivingTicketWarning && (
          <Typography sx={{ mt: -2, color: 'grey.800' }} variant='caption'>
            {t('givingTicketWarning')}
          </Typography>
        )}
        <BashButton
          type={BashButtonType.PRIMARY}
          onClick={onSaveClick}
          sx={{ height: 40, alignSelf: 'flex-end' }}
          loading={confirming}
        >
          {t('changeStatus')}
        </BashButton>
        <Menu
          sx={{ minHeight: '0px' }}
          slotProps={{
            paper: {
              sx: (theme) => ({
                maxWidth: '359px',
                [theme.breakpoints.down('md')]: {
                  left: '0 !important',
                },
              }),
            },
          }}
          MenuListProps={{
            sx: {
              p: 0,
            },
          }}
          id='create-menu'
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleMenuClose}
          disableAutoFocusItem
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem
            sx={{ p: 1.5, display: 'flex', alignItems: 'center', gap: 1 }}
            onClick={() => onDropdownItemClick(RsvpStatus.GOING)}
          >
            <CheckCircleIcon sx={{ color: '#8B30F3' }} />
            <Typography variant='body2'>
              {t('shared:dropdownRsvp.GOING')}
            </Typography>
          </MenuItem>

          <MenuItem
            sx={{ p: 1.5, display: 'flex', alignItems: 'center', gap: 1 }}
            onClick={() => onDropdownItemClick(RsvpStatus.MAYBE)}
          >
            <SvgIcon component={MaybeIcon} sx={{ color: '#2E60DC' }} />
            <Typography variant='body2'>
              {t('shared:dropdownRsvp.MAYBE')}
            </Typography>
          </MenuItem>

          <MenuItem
            sx={{ p: 1.5, display: 'flex', alignItems: 'center', gap: 1 }}
            onClick={() => onDropdownItemClick(RsvpStatus.CANT)}
          >
            <SvgIcon component={CantIcon} sx={{ color: '#EE6C4D' }} />
            <Typography variant='body2'>
              {t('shared:dropdownRsvp.CANT')}
            </Typography>
          </MenuItem>
        </Menu>
      </Column>

      <ConfirmBottomSheet
        open={confirmOpen}
        onClose={onConfirmClose}
        title={t(
          localStatus === RsvpStatus.GOING
            ? 'addToGuestListTitle'
            : 'cancelTicketsTitle',
        )}
        description={t(
          localStatus === RsvpStatus.GOING
            ? 'addToGuestListDescription'
            : 'cancelTicketsDescription',
        )}
        cancelText={t('no')}
        confirmText={t(
          localStatus === RsvpStatus.GOING
            ? 'addToGuestListConfirm'
            : 'cancelTicketsConfirm',
        )}
        onConfirm={onConfirmSave}
        loading={confirming}
        isDanger={localStatus !== RsvpStatus.GOING}
      />
    </>
  )
}

export default GuestManagementChangeStatus
