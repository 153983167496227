import { SxProps, Typography } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import { ChevronLeft, Close } from '@mui/icons-material'
import React from 'react'
import BashIconButton from '../../shared-components/buttons/BashIconButton'
import { BashButtonType } from '../../shared-components/buttons/BashButton'
import Row from '../Row'
import { Theme } from '@mui/material/styles'

interface Props {
  title: string
  onClose?: (event?: object, reason?: string) => void | undefined
  onBack?: () => void | undefined
  rightContent?: React.ReactNode | undefined
  sx?: SxProps<Theme>
}

const BashDialogTitle = ({
  onClose,
  onBack,
  title,
  rightContent,
  sx = [],
}: Props) => {
  const closeButtonSx: SxProps = {
    color: 'text.primary',
    position: 'absolute',
    top: '50%',
    left: -16,
    transform: 'translateY(-50%)',
    // ml: -2,
    // transform: 'translateY(-8px)',
  }

  return (
    <DialogTitle sx={sx}>
      <Row
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: title ? '1fr auto 1fr' : '1fr auto',
          width: 1,
          position: 'relative',
          alignItems: 'center',
          gap: theme.spacing(1.5),
          [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: title ? '1fr 2fr 1fr' : '1fr auto',
            gap: theme.spacing(1),
          },
        })}
      >
        <Row
          sx={{
            width: 40,
          }}
        >
          {onClose && (
            <BashIconButton
              sx={closeButtonSx}
              type={BashButtonType.CLEAR_GREY}
              onClick={onClose}
            >
              <Close />
            </BashIconButton>
          )}
          {onBack && (
            <BashIconButton
              sx={closeButtonSx}
              type={BashButtonType.CLEAR_GREY}
              onClick={onBack}
            >
              <ChevronLeft />
            </BashIconButton>
          )}
        </Row>
        {title && (
          <Row>
            <Typography
              sx={{
                textAlign: 'center',
              }}
              variant='h6'
            >
              {title}
            </Typography>
          </Row>
        )}

        <Row
          sx={{
            justifyContent: 'flex-end',
          }}
        >
          {rightContent}
        </Row>
      </Row>
    </DialogTitle>
  )
}

export default BashDialogTitle
